import React, {Component} from 'react';
import './Loader.css'

type Props = {
}

type State = {
};

export default class Loader extends Component<Props, State> {

  render() {
    return (
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
  }
}
