import React, {Component, RefObject} from 'react';
import ReactPinField, {PinField} from "react-pin-field";

type Props = {
  onChange: (code: string) => void;
}

type State = {
  code: string;
};

export default class PinInput extends Component<Props, State> {
  pinField: RefObject<PinField> = React.createRef();

  constructor(props: Props) {
    super(props);
    this.state = {
      code: '',
    };
  }

  setSuccess = () => {
    if (this.pinField && this.pinField.current) {
      this.pinField.current.setAttribute("completed", "");
    }
  }

  setError = () => {
    if (this.pinField && this.pinField.current) {
      this.pinField.current.setAttribute("error", "");
    }
  }

  getCode = () => {
    return this.state.code;
  }

  reset = () => {
    if (this.pinField && this.pinField.current) {
      this.pinField.current.inputs.forEach(input => (input.value = ""))
      this.pinField.current.removeAttribute("completed");
      this.pinField.current.removeAttribute("error");
      this.pinField.current.inputs[0].focus()
    }
  }

  render() {
    return (
      <div>
        <ReactPinField
          inputMode={'numeric'}
          className="pin-field"
          length={4}
          validate={/^[0-9]$/}
          ref={this.pinField}
          onChange={(code: string) => {
            if (code.length !== 4 && this.pinField && this.pinField.current) {
              this.pinField.current.removeAttribute("completed");
              this.pinField.current.removeAttribute("error");
            }
            this.setState({code: code});
            this.props.onChange(code);
          }}
          onComplete={(code: string) => {
            if (this.pinField && this.pinField.current) {
              this.pinField.current.removeAttribute("completed");
              this.pinField.current.removeAttribute("error");
            }
          }}
        ></ReactPinField>
      </div>
    );
  }
}
