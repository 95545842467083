import React, {useState} from 'react';
import './App.css';
import IPhoneOverlay from "./IPhoneOverlay";
import MainView from "./MainView";
import PinInput from "./PinInput";
import Card from "./Card";
import Ripple from "./Ripple";
import Loader from "./Loader";
import seedrandom from 'seedrandom'

/**
 * CONSTANTS
 */
const MIN_ERROR = 1;
const MAX_ERROR = 1;
const WAIT_MS = 2500;


/**
 * VALID CODES
 */
const validCodes: Record<string, {text: string, image: string}> = {
  '1607': {
    text: 'Es una data qualsevol, enhorabona! Pots obrir nomes 1 regal.',
    image: '/valid/regal1.jpg',
  },
  '6969': {
    text: "Curiosa matricula qui la tingui. Enhorabona! Pots obrir nomes 1 regal.",
    image: '/valid/regal2.jpg',
  },
  '0846': {
    text: "Algo deuria pasar aquell any. Enhorabona! Pots obrir nomes 1 regal.",
    image: '/valid/regal3.jpg',
  },
}

/**
 * INVALID CODES
 */
const invalidCodes: Record<string, {text: string, image: string}> = {
  // Cotxes
  '2678': {
    text: 'Quines rodes tan brutes...',
    image: '/invalid/cupra-papa.jpg',
  },
  '4467': {
    text: 'El millor cotxe del mon',
    image: '/invalid/cupra-adri.jpg',
  },
  '8383': {
    text: 'El primer cotxe',
    image: '/invalid/ibiza.jpg',
  },
  '4290': {
    text: 'Visto i no visto',
    image: '/invalid/astra.jpg',
  },
  '8099': {
    text: 'Siniestro total',
    image: '/invalid/altea.jpg',
  },
  '3432': {
    text: 'El primer de la memoria',
    image: '/invalid/toledo.jpg',
  },

  // Dies festius
  '2512': {
    text: "Si tot ha anat be, es avui, Nadal",
    image: '/invalid/nadal.jpg',
  },
  '2612': {
    text: "Els millors canalons son els de l'Alba",
    image: '/invalid/canalons.jpg',
  },
  '3112': {
    text: "Tic tac, campanades",
    image: '/invalid/2022.jpg',
  },

  // Dates importants
  '2901': {
    text: "Cruel",
    image: '/invalid/marta.jpg',
  },
  '1210': {
    text: "Podria ser correcte, pero no",
    image: '/invalid/adri-alba.jpg',
  },
  '2506': {
    text: "Egocentrica",
    image: '/invalid/alba.jpg',
  },
  '2408': {
    text: "Dia recordat per tota la humanitat",
    image: '/invalid/adri.jpg',
  },
  '3010': {
    text: "Les millors peludes i orelludes",
    image: '/invalid/mia-shiva.jpg',
  },
  '2509': {
    text: "Gordis golafres",
    image: '/invalid/lia-akira.jpg',
  },

  // Anys
  '1993': {
    text: "La millor generació",
    image: '/invalid/adri-alba.jpg',
  },
  '2017': {
    text: "DEP: El millor conill del mon",
    image: '/invalid/puki.jpg',
  },
  '2019': {
    text: "Un nuevo virus",
    image: '/invalid/covid.jpg',
  },
  '2020': {
    text: "La vacuna contraataca",
    image: '/invalid/vacuna.jpg',
  },
  '2021': {
    text: "El retorno del Covid (Omicron)",
    image: '/invalid/covidd.jpg',
  },
}

enum APP_STATE {
  ENTER_CODE = 0,
  READY_TO_VALIDATE = 1,
  VALIDATING = 2,
  VALIDATED = 3,
  ERROR = 4
}

function getRandomInt(seed: string) {
  const mathRandom = seedrandom(seed);
  return Math.floor(mathRandom() * (MAX_ERROR + 1 - MIN_ERROR)) + MIN_ERROR;
}

function App() {
  const pinInput: any = React.createRef();

  const [appState, setAppState] = useState(APP_STATE.ENTER_CODE);
  const [code, setCode] = useState('');

  const validatePin = () => {
    const code = pinInput.current.getCode();
    setCode(code);
    setAppState(APP_STATE.VALIDATING)
    const pinInputCurrent = pinInput.current;
    setTimeout(() => {
      if (validCodes[code]) {
        setAppState(APP_STATE.VALIDATED);
        pinInputCurrent.setSuccess();
      } else {
        setAppState(APP_STATE.ERROR);
        pinInputCurrent.setError();
      }
    }, WAIT_MS)
  }

  const resetAll = () => {
    setAppState(APP_STATE.ENTER_CODE)
    pinInput.current.reset();
  }

  const getCard = () => {
    if (validCodes[code]) {
      return {
        valid: true,
        ...validCodes[code],
      }
    } else if (invalidCodes[code]) {
      return {
        valid: false,
        ...invalidCodes[code],
      }
    } else {
      return {
        valid: false,
        text: '',
        image: `/errors/${getRandomInt(code)}.jpg`,
      }
    }
  }

  return (
    <div className="App">
      <IPhoneOverlay/>
      <MainView>
        <div className="App-header">
          <h2 style={{marginBottom: 0, marginTop: 5}}>
            Introdueix el codi correcte
          </h2>
          <p style={{marginTop: 5}}>
            Trobaras les pistes en els sobres
          </p>
          <PinInput
            ref={pinInput}
            onChange={(code: string) => {
              if (code.length !== 4) {
                setAppState(APP_STATE.ENTER_CODE);
              } else {
                setAppState(APP_STATE.READY_TO_VALIDATE);
              }
            }}
          />
          {(appState === APP_STATE.ENTER_CODE || appState === APP_STATE.VALIDATING || appState === APP_STATE.READY_TO_VALIDATE) && <Ripple
            classes="btn"
            disabled={appState !== APP_STATE.READY_TO_VALIDATE}
            onClickHandler={validatePin}
            >
            Validar
            </Ripple>
          }
          {(appState === APP_STATE.VALIDATED || appState === APP_STATE.ERROR) && <Ripple
            classes="btn btn-blue"
            disabled={false}
            onClickHandler={resetAll}
          >
            Introduir un altre codi
          </Ripple>
          }
          <div style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
          }}>
            {appState === APP_STATE.VALIDATING && <Loader/>}
            {(appState === APP_STATE.VALIDATED || appState === APP_STATE.ERROR) && <Card
                  display={true}
                  title={code}
                  description={getCard().text}
                  valid={getCard().valid}
                  image={getCard().image}
              />}
          </div>
        </div>
      </MainView>
    </div>
  );
}

export default App;
