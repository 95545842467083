import React from "react";
import './Card.css';

const Card = (props: {title: string, description: string, image: string, display: boolean, valid: boolean}) => {
  if (!props.display) return null;
  let cardClass = 'Card';
  if (props.valid) {
    cardClass += " valid"
  } else {
    cardClass += " invalid"
  }
  return (
    <div className={cardClass}>
      <div className={'header'}>
        <div className={'imageWrapper'}>
          <img src={props.image} className={'image'} alt="" />
        </div>
      </div>
      <div className={'body'}>
        <div className={'textWrapper'}>
          <h3 className={'title'}>{`${props.title}`}</h3>
          <div style={{flexGrow: 1}}/>
          {!props.valid && <p className={'description'}>{'Segueix intentant'}</p>}
          {props.valid && <p className={'description'}>{'Correcte!'}</p>}
        </div>
        {props.description && <div className={'textWrapper'}>
          <p className={'description'}>{props.description}</p>
        </div>}
      </div>
    </div>
  );
};

export default Card;
