import React, {Component, CSSProperties} from 'react';

const IPhoneOverlayStyle: CSSProperties = {
  position: 'absolute',
  margin: 'auto',
  maxHeight: 850,
  maxWidth: 420,
  height: '100%',
  width: 420,
  borderRadius: 40,
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  boxShadow: '0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111',
  pointerEvents: 'none',
};

type Props = {};

export default class IPhoneOverlay extends Component<Props> {
  render() {
    if (window.outerWidth <= 420) return null;
    return (
      <div style={IPhoneOverlayStyle}>
        <style>{'#root {background: -webkit-linear-gradient(top, rgb(206, 165, 0) 21%, rgb(252, 235, 7) 72%); }'}</style>
        {this.props.children}
      </div>
    );
  }
}
